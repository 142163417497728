import React, { useRef } from "react"
import Layout from "@utils/layout"
import SEO from "@utils/seo"
import SectionHeader from "@molecules/sectionHeader"
import SectionSetup from "@molecules/sectionSetup"
import SectionCheckout from "@molecules/sectionCheckout"
import SectionServices from "@molecules/sectionServices"

const IndexPage = () => {

  const myRef = useRef(null);

  return (
    <Layout>
      <SEO title="Home" />
      <SectionHeader refProp={myRef} />
      <SectionSetup />
      <SectionServices />
      <SectionCheckout refProp={myRef} />
    </Layout>
  )
}

export default IndexPage
