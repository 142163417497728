import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { media } from "@utils/media"
import Button from "@atoms/button"
import Subheading from "@atoms/subheading"

const SectionHeader = (props) => {
  const data = useStaticQuery(graphql`
    query HeaderImageQuery {
      file(relativePath: { eq: "top.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  const executeScroll = () => props.refProp.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
  return (
    <HeaderContainer contentTop content="true" grid backgroundSecondary>
      <Image>
        <Img fluid={data.file.childImageSharp.fluid} />
      </Image>
      <Content>
        <Subheading>Get Started</Subheading>
        <H1Heading>Quickly create gift cards for your business</H1Heading>
        {/* <Button href="#checkout">Create a free account</Button> */}
        <Button onClick={executeScroll}>Create a free account</Button>
      </Content>
      <HeaderCurve fill="none" viewBox="0 0 1680 232">
        <path
          fill="#fdf8ed"
          d="M0 0h1680v119.847S1428.43 231 1260 231C967.343 231 712.766 9.26 420 9.26 251.618 9.26 0 119.848 0 119.848V0z"
        ></path>
      </HeaderCurve>
    </HeaderContainer>
  )
}

export default SectionHeader

const HeaderContainer = styled.header`
  align-items: center;
  background-color: ${props => props.theme.colors.secondary};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
  margin-bottom: 4rem;
  padding: 0 var(--spacingContent);

  @media ${media.lg} {
    flex-direction: row-reverse;
    min-height: 800px;
  }
`

const H1Heading = styled.h1`
  color: inherit;
  margin-bottom: 1rem;
  font-size: 2.0rem;

  @media ${media.md} {
    margin-bottom: 2rem;
    font-size: 3.5rem;
  }

  @media ${media.lg} {
    font-size: 3.5rem;
    margin-bottom: 3rem;
  }
`

const Image = styled.div`
  display: block;
  flex-basis: 100%;
  padding-top: 8rem;
  width: 100%;

  @media ${media.md} {
    padding-top: 2.8rem;
    width: 70%;
    flex-basis: 60%;
  } 
  @media ${media.lg} {
    padding: 0;
    width: 60%;
    flex-basis: 60%;
  }

  .gatsby-image-wrapper {
    width: 80%;
    margin: 0 auto;
  }
`

const Content = styled.div`
  display: block;
  flex-basis: 100%;
  width: 100%;

  h1 {
    margin-bottom: 2rem;
  }

  @media ${media.xs} {
    padding-bottom: 2rem;
    h1 {
      margin-bottom: 2rem;
    }
  }

  @media ${media.lg} {
    width: 40%;
    flex-basis: 40%;
  }
`

// @media ${media.md} {
//   h1 {
//     margin-bottom: 1rem;
//   }
// }

const HeaderCurve = styled.svg`
  top: 100vh;
  left: 0;
  position: absolute;
  width: 100vw;
  height: auto;
  z-index: -1;
`
